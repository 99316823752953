.certificates-card {
    position: relative;
    perspective: 1000px; /* Define la perspectiva para la animación 3D */
}

.certificates-card .card {
    transition: transform 0.8s ease; /* Ajusta la duración de la transición a 0.8 segundos para un giro más lento */
    transform-style: preserve-3d; /* Mantiene la estructura 3D al rotar */
}

.certificates-card:hover .card {
    transform: rotateY(180deg); /* Gira la tarjeta 180 grados al pasar el mouse */
}

.certificates-card .card-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Evita que se vea el reverso de la tarjeta */
    transform: rotateY(0deg);
    transition: opacity 0.5s ease, visibility 0.5s ease; /* Transición suave para la opacidad y visibilidad */
    background-color: rgba(0, 0, 0, 0.8); /* Fondo semitransparente */
    color: #fff;
    padding: 20px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.certificates-card:hover .card-body {
    transform: rotateY(180deg); /* Gira el cuerpo de la tarjeta al estar visible */
    opacity: 1;
    visibility: visible;
}

.certificates-description {
    font-size: 1rem; /* Ajusta el tamaño de la fuente según tus necesidades */
    line-height: 1.5; /* Ajusta la altura de línea para mejorar la legibilidad */
    color: #fff; /* Color del texto */
}

.certificates-description a {
    color: #007bff; /* Color del enlace */
    text-decoration: none; /* Elimina el subrayado del enlace */
    transition: color 0.3s ease;
}

.certificates-description a:hover {
    color: #0056b3; /* Color del enlace al pasar el mouse */
}