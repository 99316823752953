.home-section {
    background-color: #343a40; /* Un fondo oscuro para mejor contraste */
    padding: 250px 0; /* Aumenta el espaciado superior e inferior */
}

.home-section h1 {
    margin-bottom: 20px; /* Espacio debajo del título */
    font-size: 2.5rem; /* Ajusta el tamaño del texto del título */
    color: white; /* Asegúrate de que el color del texto sea blanco */
}

.home-section p {
    margin-bottom: 250px;
    font-size: 1.2rem; /* Tamaño de fuente para el párrafo */
    color: white; /* Asegúrate de que el color del texto sea blanco */
}
