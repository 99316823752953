.project-banner {
    margin-bottom: 20px; /* Espacio entre los banners */
}

.banner-card {
    display: flex;
    border: none; /* Elimina el borde de la tarjeta */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para dar un efecto de profundidad */
    overflow: hidden; /* Esconde los bordes sobrantes */
    transition: transform 0.3s ease; /* Transición suave para hover */
}

.banner-card:hover {
    transform: scale(1.05); /* Efecto de zoom al pasar el mouse */
}

.banner-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: #f8f9fa; /* Fondo claro para el cuerpo */
}

.banner-body .card-title {
    font-size: 1.5rem; /* Tamaño de la fuente del título */
    margin-bottom: 10px;
    color: #007bff; /* Color del título */
}

.banner-body .project-description {
    font-size: 1rem; /* Tamaño de la fuente del texto */
    color: #333; /* Color del texto */
}

.banner-body .project-description a {
    color: #007bff; /* Color del enlace */
    text-decoration: none; /* Elimina el subrayado del enlace */
    transition: color 0.3s ease;
}

.banner-body .project-description a:hover {
    color: #0056b3; /* Color del enlace al pasar el mouse */
}

.card-img {
    object-fit: cover; /* Ajusta la imagen para cubrir el área sin distorsionarse */
    height: 100%; /* Ajusta la altura de la imagen al contenedor */
}
