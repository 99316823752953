.footer {
    background-color: #343a40;
    color: #fff;
    padding: 40px 0;
}

.footer h5 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.footer p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.footer .social-icons {
    display: flex;
    gap: 15px;
}

.footer .social-icons a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.footer .social-icons a:hover {
    color: #007bff;
}

.footer-section {
    margin-bottom: 20px;
}

.text-center {
    text-align: center;
}