body {
  padding-top: 56px;
}

.home-section {
  background: url('./img/img1.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  padding: 60px 0;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.text-center {
  text-align: center;
}

.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366; /* Color de fondo de WhatsApp */
  border-radius: 50%;
  padding: 15px; /* Reduce el padding para un mejor aspecto */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Mejora la sombra para un efecto más suave */
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  color: white; /* Color del ícono */
  text-align: center; /* Centra el ícono dentro del botón */
  transition: background-color 0.3s, transform 0.3s; /* Agrega una transición para un efecto suave */
  display: flex; /* Flex para centrar el ícono */
  align-items: center; /* Centra el ícono verticalmente */
  justify-content: center; /* Centra el ícono horizontalmente */
  width: 56px; /* Tamaño fijo del botón */
  height: 56px; /* Tamaño fijo del botón */
}

.whatsapp-float:hover {
  background-color: #128C7E; /* Color más oscuro al pasar el ratón */
  transform: scale(1.1); /* Efecto de aumento al pasar el ratón */
}

@media (max-width: 576px) {
  .whatsapp-float {
      bottom: 15px; /* Ajusta el espacio inferior en pantallas pequeñas */
      right: 15px; /* Ajusta el espacio derecho en pantallas pequeñas */
      width: 50px; /* Tamaño más pequeño para pantallas pequeñas */
      height: 50px; /* Tamaño más pequeño para pantallas pequeñas */
  }
}

@media (max-width: 768px) {
  .whatsapp-float {
      bottom: 20px; /* Ajusta el espacio inferior en pantallas medianas */
      right: 20px; /* Ajusta el espacio derecho en pantallas medianas */
      width: 56px; /* Tamaño fijo para pantallas medianas */
      height: 56px; /* Tamaño fijo para pantallas medianas */
  }
}

