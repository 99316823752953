.section-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #343a40;
    text-align: center;
    position: relative;
    display: inline-block;
}

.section-title::after {
    content: '';
    width: 50px;
    height: 4px;
    background-color: #007bff;
    display: block;
    margin: 10px auto 0;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #6c757d;
    text-align: justify;
}

#about {
    padding: 80px 0; /* Ajusta el padding para la sección */
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

#about .container {
    max-width: 960px; /* Limita el ancho del contenedor para un diseño más ajustado */
}

.about-image {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-image:hover {
    transform: scale(1.05);
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2) !important;
}

.skills-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #343a40;
    text-align: center;
    position: relative;
    display: inline-block;
}

.skills-title::after {
    content: '';
    width: 50px;
    height: 4px;
    background-color: #007bff;
    display: block;
    margin: 10px auto 0;
}

.skills {
    max-width: 800px;
    margin: 0 auto;
}

.skill {
    position: relative;
}

.skill span {
    font-size: 1rem;
    font-weight: 500;
    color: #343a40;
}

.skill .progress-bar {
    background-color: #007bff;
    transition: width 0.6s ease, background-color 0.3s ease;
}

.skill .progress-bar:hover {
    background-color: #0056b3;
}

.skill .progress {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    overflow: hidden;
}
