.section {
    padding: 60px 0;
}

.contact-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.section-subtitle {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 40px;
    color: #666;
}

.contact-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.contact-icon {
    font-size: 1.5rem;
    color: #007bff;
    margin-right: 10px;
}

.contact-link {
    font-size: 1.1rem;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #0056b3;
}

.social-links {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.contact-schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.schedule-item {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.schedule-item p {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
}

.schedule-item a {
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
}

.schedule-item a:hover {
    background-color: #0056b3;
}

@media (max-width: 767px) {
    .contact-wrapper {
        padding: 10px;
    }

    .contact-item {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

    .contact-icon {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .contact-link {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .contact-info {
        text-align: center;
    }

    .contact-item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px; /* Reduce margin-bottom for smaller screens */
    }

    .contact-icon {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .contact-link {
        font-size: 1rem;
    }
}
