body {
    padding-top: 70px; /* Ajusta este valor si tu navbar tiene un tamaño diferente */
    background-color: #f8f9fa;
}

.navbar {
    margin-bottom: 0; /* Elimina el margen inferior de la navbar */
}

.section {
    padding: 60px 0; /* Ajusta el padding para cada sección */
}

.navbar .nav-link {
    color: #fff !important;
    white-space: nowrap; /* Prevenir el salto de línea */
    padding-left: 1rem; /* Ajusta el padding para más espacio */
    padding-right: 1rem; /* Ajusta el padding para más espacio */
}

.navbar .nav-link:hover {
    color: #bbb !important;
}

.navbar .form-control {
    margin-right: 10px;
    width: 200px; /* Ajusta el ancho del campo de búsqueda */
    background-color: #343a40;
    border: 1px solid #555;
    color: #fff;
}

.navbar .form-control::placeholder {
    color: #ccc;
}

.contact-info {
    color: #fff;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.info {
    color: #fff;
}

.me-2 {
    color: #fff;
}

.contact-info .me-2, .contact-info .ms-4 {
    font-size: 1rem;
}

.contact-info span {
    margin-left: 5px;
}

@media (min-width: 992px) {
    .navbar .form-control {
        width: 300px; /* Ancho mayor para pantallas grandes */
    }

    .navbar-collapse {
        display: flex;
        justify-content: space-between;
    }
}

.search-results-container {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    top: 70px; /* Ajusta este valor según la altura de tu navbar */
    width: 100%;
    max-width: 800px; /* Limita el ancho de los resultados de búsqueda */
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-results-container .search-result-item {
    border: none;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s;
}

.search-results-container .search-result-item:hover {
    background-color: #f8f9fa;
}

.search-results-container .search-result-link {
    color: #007bff;
    font-weight: 500;
}

.search-results-container .search-result-link:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .navbar .form-control {
        width: 150px; /* Ancho reducido para pantallas pequeñas */
    }
}
